import React, { useCallback, useEffect, useState } from 'react'
import { useWallet } from 'use-wallet'
import { provider } from 'web3-core'
import { getUserAddressByIndex } from '../hooks/finance'
import { getTotalDeposit, getUserBaseProfit, getUserExpProfit } from '../../Defi/hooks/finance'
import { getDisplayBalance } from '../../../utils/formatBalance'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { getReferee, getReferrals } from '../../Defi/hooks/invite'
import formatDuration from '../../../utils/formatDuration'
import { FinanceContractAddress } from '../../Defi/hooks/constants'

const DepositRow: ({ index: number }) => JSX.Element = ({ index }) => {
  const { account, ethereum } = useWallet<provider>()
  const [showDetail, setShowDetail] = useState(false)

  const [address, setAddress] = useState('-')
  const [referee, setReferee] = useState('-')
  const [totalDeposit, setTotalDeposit] = useState('-')

  const [baseProfit, setBaseProfit] = useState('-')
  const [baseProfit2, setBaseProfit2] = useState(0)
  const [expProfit, setExpProfit] = useState('-')
  const [expProfit2, setExpProfit2] = useState(0)
  const [referrals, setReferrals] = useState(null)

  const totalDepositBN = new BigNumber(totalDeposit)
  const baseProfitBN = new BigNumber(baseProfit)
  const expProfitBN = new BigNumber(expProfit)

  useEffect(() => {
    getUserAddressByIndex(ethereum, index).then(value => {
      setAddress(value)
      getReferee(ethereum, value).then(value2 => {
        setReferee(value2)
      })
      getTotalDeposit(ethereum, value).then(value2 => {
        setTotalDeposit(value2)
      })
      getReferrals(ethereum, value).then(value2 => {
        setReferrals(value2)
      })
      getUserBaseProfit(ethereum, value).then(value2 => {
        setBaseProfit(value2[0])
        setBaseProfit2(value2[1])
      })
      getUserExpProfit(ethereum, value).then(value2 => {
        setExpProfit(value2[0])
        setExpProfit2(value2[1])
      })
    })
  }, [account, ethereum])

  return (
    <Row className="w-full px-10 text-xs flex flex-row">
      <div className="w-1/5 text-left break-words">
        <div>{address}</div>
        <div>Referee: {referee == FinanceContractAddress ? '-' : referee}</div>
      </div>
      <div className="w-1/5 px-2 text-center">{getDisplayBalance(totalDepositBN.times(0.4))} DBT</div>
      <div className="w-1/5 px-2 text-center">
        <div>{getDisplayBalance(baseProfitBN.times(0.4))} DBT</div>
        <div>{formatDuration(baseProfit2)}</div>
      </div>
      <div className="w-1/5 px-2 text-center">
        <div>{getDisplayBalance(expProfitBN.times(0.4))} DBT</div>
        <div>{formatDuration(expProfit2)}</div>
      </div>
      <div className="w-1/5 px-2 text-center">
        {referrals ? referrals.length : '-'}
        {referrals && referrals.length > 0 && (<span className="ml-1 cursor-pointer underline" onClick={() => setShowDetail(!showDetail)}>(Detail)</span>)}
        {showDetail && (
          referrals.map(i => <div>{i}</div>)
        )}
      </div>
    </Row>
  )
}

const Row = styled.div`
  border-top: 1px solid #cccccc;
  padding: 20px;
`

export default DepositRow
