import React, { useCallback, useEffect, useState } from 'react'
import { useWallet } from 'use-wallet'
import { provider } from 'web3-core'
import { Card, CardHeader } from './Common'
import { getUsersNum } from '../hooks/finance'
import DepositRow from './DepositRow'
import styled from 'styled-components'

const Deposits: React.FC = () => {
  const { account, ethereum } = useWallet<provider>()

  const [usersNum, setUsersNum] = useState(0)

  useEffect(() => {
    getUsersNum(ethereum).then(value => {
      setUsersNum(parseInt(value || 0))
    })
  }, [account, ethereum])

  return (
    <Card className="flex flex-col justify-center items-center text-center py-2 text-xs">
      <CardHeader>Total User: {usersNum}</CardHeader>
      <Row className="flex flex-row justify-between items-center w-full px-10 opacity-50 pl-2 pr-2">
        <div className="w-1/6 px-2 text-center">Address</div>
        <div className="w-1/6 px-2 text-center">Deposit</div>
        <div className="w-1/6 px-2 text-center">Basic Compensation</div>
        <div className="w-1/6 px-2 text-center">Binary System Rewards</div>
        <div className="w-1/6 px-2 text-center">Title Rewards</div>
        <div className="w-1/6 px-2 text-center">Mega Match Rewards</div>
        <div className="w-1/6 px-2 text-center">Referral</div>
      </Row>
      {
        new Array(usersNum).fill(0).map((i, index) => (
          <DepositRow key={`referral_${index}`} index={index} />
        ))
      }
    </Card>
  )
}

const Row = styled.div`
  border-top: 1px solid #cccccc;
  padding: 20px;
`

export default Deposits
