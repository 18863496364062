import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import AccountButton from './components/AccountButton'
import logoIcon from '../../assets/images/logo.svg'
import styled from 'styled-components'

const TopBar: React.FC = () => {
  return (
    <div className="container text-white z-50 absolute px-4" style={{ left: '50%', transform: 'translateX(-50%)' }}>
      <div className="flex flex-row justify-between items-center h-16">
        <div className="flex justify-start">
          <NavLink exact activeClassName="active" to="/" className="flex flex-row justify-center items-center flex-wrap">
            <img src={logoIcon} alt="" className="h-8 mr-2" style={{ transform: 'rotateZ(45deg)' }} />
            <span className="text-primary text-xl font-bold">DBCG DEFI</span>
            <RouterName></RouterName>
          </NavLink>
        </div>
        <div className="hidden lg:flex">
          <NavItem className="mr-6" href="/#features">Features</NavItem>
          <NavItem className="mr-6" href="/#howtojoin">How to Join</NavItem>
          <NavItem className="mr-6" href="/#investment">Investment Conditions</NavItem>
          <NavItem className="mr-6" href="/#roadmap">Roadmap</NavItem>
          <NavLink className="mr-6" exact to="/uni">UNI APP</NavLink>
          <NavLink exact to="/binary">Binary APP</NavLink>
        </div>
        <div className="flex justify-end">
          <AccountButton />
        </div>
      </div>
    </div>
  )
}

function RouterName() {
  let { pathname } = useLocation();
  const flag = (pathname.includes('proxy') || pathname.includes('uni') || pathname.includes('binary')) && !pathname.includes('admin');

  return (
    <div className="lg:hidden">
      { flag && <CurrentTag>
        {pathname.replace('/', '').toUpperCase()}
      </CurrentTag>}
    </div>
  );
}

const NavItem = styled.a`
  color: white;
  padding-bottom: 0.3rem;

  &:hover {
    color: #F0B90B;
  }
`

const CurrentTag = styled.span`
  background: #F0B90B;
  padding: 4px 6px;
  border-radius: 8px;
  color: #000;
  margin-left: 5px;
  font-weight: bold;
  font-size: 12px;
`

export default TopBar
