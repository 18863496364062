import React, { useEffect, useState } from 'react'
import Page from '../../components/Page'
import Deposits from './components/Deposits'
import { useWallet } from 'use-wallet'

const AdminDeposits: React.FC = () => {
  const { connect } = useWallet()
  useEffect(() => {
    connect('injected')
  }, [])

  return (
    <Page>
      <div className="mt-24" />
      <Deposits />
    </Page>
  )
}

export default AdminDeposits
