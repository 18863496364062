const theme = {
  borderRadius: 4,
  breakpoints: {
    mobile: 400,
  },
  color: {
    black: '#212833',
    grey: '#333333',
    white: '#ffffff',
    primary: {
      normal: '#F0B90B',
      hover: '#F8D12F',
    },
    secondary: {
      normal: '#ffffff',
      hover: '#00000005',
    },
  },
  siteWidth: 1200,
  spacing: {
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 48,
    7: 64,
  },
  topBarSize: 72,
  boxShadow: 'rgb(234, 236, 239) 0px 0px 0px 1px inset',
}

export default theme
