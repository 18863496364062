import React from 'react'
import styled from 'styled-components'

const Roadmap: React.FC = () => {
  return (
    <div id="roadmap" className="w-screen relative px-10 py-20 text-white">
      <div className="container">
        <div className="text-center text-3xl font-bold">Roadmap</div>

        <div className="roadmap-wrapper">
          <ul className="roadmap mobile">
            <li className="complete">
              <div className="marker"></div>
              <div className="text">
                <h1>2021/04/01</h1>
                <p>Point business begins</p>
              </div>
            </li>

            <li className="">
              <div className="marker"></div>
              <div className="text">
                <h1>2021/07/30</h1>
                <p>Point can be changed to stock of LLC. Operation with crypto assets begins.</p>
              </div>
            </li>

            <li className="">
              <div className="marker"></div>
              <div className="text">
                <h1>2021/11/30</h1>
                <p>Governance token distribution / listing</p>
              </div>
            </li>
          </ul>

          <div className="timeline"></div>
        </div>

      </div>
    </div>
  )
}

export default Roadmap
