import React, { useRef, useEffect, useState } from 'react'
import { useWallet } from 'use-wallet'
import { provider } from 'web3-core'
import { Card, CardHeader } from './Common'
import { getUsersNum, getUserAddressByIndex, getUserTotalDepositNum, getUserDepositRecordByIndex } from '../../BinaryAdmin/hooks/finance'
import { getDisplayBalance } from '../../../utils/formatBalance'
import BigNumber from 'bignumber.js/bignumber'

const Deposits: React.FC = () => {
  const { account, ethereum } = useWallet<provider>()
  const [rows, setRows] = useState([])

  const [userCount, setUserCount] = useState(0)
  const [loadedUserCount, setLoadedUserCount] = useState(0)

  useEffect(() => {
    setRows([])
    getUsersNum(ethereum).then(async usersNum => {
      setUserCount(usersNum)
      for (let i = 0; i < usersNum; i++) {
        const address = await getUserAddressByIndex(ethereum, i)
        const depositNum = await getUserTotalDepositNum(ethereum, address)
        for (let j = 0; j < depositNum; j++) {
          const deposit = await getUserDepositRecordByIndex(ethereum, address, j)
          setRows((rows) => [...rows, [deposit[0], address, deposit[1]]])
        }
        setLoadedUserCount(i + 1)
      }
      setRows((rows) => [...rows.sort((a, b) => a[0] - b[0])])
    })
  }, [account, ethereum])

  return (
    <Card className="flex flex-col justify-center items-center text-center py-2 text-xs">
      {
        loadedUserCount !== userCount && <div>loading: {loadedUserCount} / {userCount}</div>
      }
      <CardHeader>Total Deposits: {rows.length}</CardHeader>
      <div className="flex flex-row justify-between items-center w-full opacity-50">
        <div className="flex-1 px-2 text-center">Index</div>
        <div className="flex-1 px-2 text-center">Time</div>
        <div className="flex-1 px-2 text-center">Address</div>
        <div className="flex-1 px-2 text-center">Amount</div>
      </div>
      {
        rows.map((i, index) => (
          <div key={`deposit_${index}`} className="flex flex-row justify-between items-center w-full">
            <div className="flex-1 px-2 text-center">{index + 1}</div>
            <div className="flex-1 px-2 text-center">{new Date(i[0] * 1000).toUTCString()}</div>
            <div className="flex-1 px-2 text-center">{i[1]}</div>
            <div className="flex-1 px-2 text-center">{i[2] === '0' ? 'Withdrawed' : getDisplayBalance(new BigNumber(i[2]).multipliedBy(0.4))}</div>
          </div>
        ))
      }
    </Card>
  )
}

export default Deposits
