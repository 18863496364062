import React, { useCallback, useState } from "react";
import { provider } from "web3-core";
import styled from "styled-components";
import BigNumber from "bignumber.js/bignumber";
import { useWallet } from "use-wallet";
import { Card, CardHeader } from "./Common";
import Button from "../../../components/Button";
import {
  useProfitWithdrawLimit,
  useWithdrawApplication,
  useUserBaseProfit,
  useUserExpProfit,
  useReferralRewards
} from "../hooks/useFinance";
import { onHarvestCurrency, onHelpReferral } from "../hooks/finance";
import { getDisplayBalance } from "../../../utils/formatBalance";
import formatDuration from "../../../utils/formatDuration";

const Deposit: React.FC = () => {
  const [sendAmount, setSendAmount] = useState("0");
  const [sendAddress, setSendAddress] = useState("0x000000000000");
  const profitWithdrawLimit = useProfitWithdrawLimit();
  const { amount: baseAmount, duration: baseDuration } = useUserBaseProfit();
  const { amount: expAmount, duration: expDuration } = useUserExpProfit();
  const { account, ethereum } = useWallet<provider>();
  const { withdrawingAmount, withdrawingCountdown } = useWithdrawApplication();

  console.log("baseDuration", baseDuration, expDuration);

  const baseAmountBN = new BigNumber(baseAmount);
  const expAmountBN = new BigNumber(expAmount);
  const profitWithdrawLimitBN = new BigNumber(profitWithdrawLimit);
  const sendingAmountBN = new BigNumber(sendAmount);

  const baseDurationNumber = baseDuration ? parseInt(baseDuration) : null;
  const baseDurationString = baseDurationNumber
    ? formatDuration(baseDurationNumber)
    : "-";
  const expDurationNumber = baseDuration ? parseInt(expDuration) : null;
  const expDurationString = expDurationNumber
    ? formatDuration(expDurationNumber)
    : "-";

  const withdrawingCountdownNumber = withdrawingCountdown
    ? parseInt(withdrawingCountdown)
    : null;
  const countDownString = withdrawingCountdownNumber
    ? formatDuration(withdrawingCountdownNumber)
    : "-";

  const sendBigNumber = new BigNumber(sendAmount)
  const helpReferral = useCallback(async () => {
    if (sendBigNumber.isNaN()) {
      alert('invalid number')
    } else {
      await onHelpReferral(
        ethereum,
        account,
        sendBigNumber.toNumber(),
        sendAddress)
    }
  }, [ethereum, account, sendBigNumber, sendAddress])

  const dbtMultiple = new BigNumber(100);

  const { referralRewards } = useReferralRewards();
  const referralRewardsBN = new BigNumber(referralRewards);

  return (
    <Card className="flex flex-col justify-center items-center text-center divide-x">
      <div className="flex-1 flex flex-col justify-start items-center py-2 w-full">
        <CardHeader>Referrer Rewards</CardHeader>
        <div className="font-bold text-primary">
          {referralRewardsBN.isNaN()
            ? "0"
            : getDisplayBalance(referralRewardsBN)}{" "}
          USDT
        </div>
      </div>

      <div
        className="flex flex-row text-xs w-full py-2 divide-y"
        style={{ borderTop: "1px solid #eeeeee" }}
      >
        <div className="flex-1 flex flex-col justify-start items-center">
          <div>Lock period: Unlocked</div>
          <Button
            // disabled={baseDuration}
            onClick={() => onHarvestCurrency(ethereum, account)}
          >
            Withdraw USDT
          </Button>
        </div>
        <div className="flex-1 flex flex-col justify-center items-center">
          <InputContainer className="mb-1">
            <StyledInput
              type="number"
              className="mr-2"
              value={sendAmount}
              onChange={(event) => setSendAmount(event.target.value)}
            />
            <div>USDT</div>
          </InputContainer>
          <InputContainer className="mb-1">
            <StyledInput
              type="text"
              className="mr-2"
              value={sendAddress}
              onChange={(event) => setSendAddress(event.target.value)}
            />
            <div>ADDRESS</div>
          </InputContainer>
          <Button
            className="flex flex-col justify-center items-center"
            disabled={
              !sendingAmountBN.isGreaterThan(0) ||
              withdrawingCountdownNumber > 0
            }
            onClick={() => helpReferral()}
          >
            Send
          </Button>
        </div>
      </div>
    </Card>
  );
};

const StyledInput = styled.input`
  outline: none;
`;

const InputContainer = styled.div`
  border: solid 1px #cccccc;
  border-radius: 40px;
  padding: 6px 10px;
  position: relative;
  display: flex;
  flex-direction: row;
`;

export default Deposit;
