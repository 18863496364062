import React, { useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'

import { Link } from 'react-router-dom'

interface ButtonProps {
  children?: React.ReactNode,
  disabled?: boolean,
  to?: string,
  href?: string,
  onClick?: () => void,
  text?: string,
  type?: 'primary' | 'secondary'
  className?: string,
}

const Button: React.FC<ButtonProps> = ({
                                         children,
                                         disabled = false,
                                         to,
                                         href,
                                         onClick,
                                         text,
                                         type = 'primary',
                                         className,
                                       }) => {
  const { color } = useContext(ThemeContext)

  let textColor: string
  let backgroundColor: string
  let hoverBackgroundColor: string
  let border: string
  let boxShadow: string

  switch (type) {
    case 'primary':
      textColor = '#000000'
      backgroundColor = color.primary.normal
      hoverBackgroundColor = color.primary.hover
      break
    case 'secondary':
      textColor = '#000000'
      backgroundColor = color.secondary.normal
      hoverBackgroundColor = color.secondary.hover
      boxShadow = 'rgb(234, 236, 239) 0px 0px 0px 1px inset'
      break
  }

  const ButtonChild = useMemo(() => {
    if (to) {
      return <Link to={to}>{text}</Link>
    } else if (href) {
      return <a href={href} target="__blank">{text}</a>
    } else {
      return text
    }
  }, [href, text, to])

  return (
    <StyledButton
      color={textColor}
      backgroundColor={backgroundColor}
      hoverBackgroundColor={hoverBackgroundColor}
      disabled={disabled}
      border={border}
      boxShadow={boxShadow}
      onClick={onClick}
      className={className}
    >
      {children}
      {ButtonChild}
    </StyledButton>
  )
}

interface StyledButtonProps {
  disabled: boolean,
  color: string,
  backgroundColor: string,
  hoverBackgroundColor: string,
  border: string,
  boxShadow: string,
}

const StyledButton = styled.button<StyledButtonProps>`
    align-items: center;
    background-color: ${props => props.backgroundColor};
    border: 0;
    box-shadow: ${props => props.boxShadow};
    border-radius: ${props => props.theme.borderRadius}px;
    color: ${props => props.disabled ? `${props.color}55` : props.color};
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: normal;
    height: 36px;
    justify-content: center;
    outline: none;
    padding-left: 10px;
    padding-right: 10px;
    pointer-events: ${props => props.disabled ? 'none' : undefined};
    &:hover {
      background-color: ${props => props.hoverBackgroundColor};
    }
`

export default Button
