import BigNumber from 'bignumber.js/bignumber'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useWallet } from 'use-wallet'
import { provider } from 'web3-core'
import Button from '../../../components/Button'
import useTokenBalance from '../../../hooks/eth/useTokenBalance'
import useAllowance from '../../../hooks/useAllowance'
import { approve } from '../../../utils/erc20'
import { getDisplayBalance } from '../../../utils/formatBalance'
import { FinanceContractAddress as v1ContractAddress } from '../../Defi/hooks/constants'
import { FinanceContractAddress as v2ContractAddress } from '../../Defi2/hooks/constants'
import { FinanceContractAddress, UsdtContractAddress } from '../hooks/constants'
import { onDeposit } from '../hooks/finance'
import { useTotalDeposit, useUserStage } from '../hooks/useFinance'
import { useReferee } from '../hooks/useInvite'
import { Card } from './Common'
import Cookies from 'js-cookie'


const Deposit: React.FC = () => {
  const [depositAmount, setDepositAmount] = useState('1000')
  const { account, ethereum } = useWallet<provider>()
  const usdtAllowance = useAllowance(ethereum, 'proxy')

  const totalDeposit = useTotalDeposit()
  const referee = useReferee()

  const isDeposited = totalDeposit.isGreaterThan(new BigNumber(0))

  const isReferred = referee && parseInt(referee) !== 0 ? true : false
  const balance = useTokenBalance(UsdtContractAddress)
  const refereeAddress1 = isReferred ? referee : Cookies.get('DEFI_MY_REFEREE')
  const refereeAddress2 = isReferred ? referee : Cookies.get('DEFI_BINARY_MY_REFEREE')
  console.log(referee, isReferred)
  const depositBigNumber = new BigNumber(depositAmount)
  const deposit = useCallback(async () => {
    if (depositBigNumber.isNaN()) {
      alert('invalid number')
    } else {
      await onDeposit(
        ethereum,
        account,
        depositBigNumber.toNumber(),
        !isDeposited && Cookies.get('DEFI_MY_REFEREE') ? refereeAddress1 : v1ContractAddress,
        !isDeposited && Cookies.get('DEFI_BINARY_MY_REFEREE') ? refereeAddress2 : v2ContractAddress)
    }
  }, [ethereum, account, depositAmount])

  const onApprove = useCallback(async () => {
    await approve(ethereum, account, 'proxy')
  }, [ethereum, account])

  return (
    <Card className="flex flex-col justify-center items-center text-center pt-4">
      <div className="flex flex-row justify-between items-center mb-3">
        <AmountLabel onClick={() => setDepositAmount('1000')}>$1000</AmountLabel>
        <AmountLabel onClick={() => setDepositAmount('5000')}>$5000</AmountLabel>
        <AmountLabel onClick={() => setDepositAmount('10000')}>$10000</AmountLabel>
        <AmountLabel onClick={() => setDepositAmount('50000')}>$50000</AmountLabel>
        <AmountLabel onClick={() => setDepositAmount('100000')}>$100000</AmountLabel>
      </div>
      <InputContainer className="mb-1">
        <StyledInput type="number" className="mr-2" value={depositAmount}
          onChange={(event) => setDepositAmount(event.target.value)} />
        <div>USDT</div>
      </InputContainer>
      <div className="opacity-50 text-sm mb-2">
        Wallet Balance: {getDisplayBalance(balance)} USDT
      </div>
      {
        depositBigNumber.isNaN() ? null : (
          usdtAllowance.isGreaterThan(depositBigNumber) ?
            (
              <Button className="mb-4 w-32" onClick={deposit} disabled={parseFloat(depositAmount) < 1}>
                Deposit
              </Button>
            )
            :
            (<Button className="mb-4 w-32" onClick={onApprove}>
              Approve
            </Button>)
        )
      }
    </Card>
  )
}

const AmountLabel = styled.div`
  border: 1px solid #eac038;
  border-radius: 20px;
  padding: 2px 6px;
  font-size: 0.875rem;
  margin: 0 4px;
`

const InputContainer = styled.div`
  border: solid 1px #cccccc;
  border-radius: 40px;
  padding: 6px 10px;
  position: relative;
  display: flex;
  flex-direction: row;
`

const StyledInput = styled.input`
  outline: none;
`

const BottomContainer = styled.div`
  border-top: 1px solid #eeeeee;
  font-size: 0.8rem;
`

export default Deposit
