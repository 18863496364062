import React, { useState } from 'react'
import { provider } from 'web3-core'
import styled from 'styled-components'
import BigNumber from 'bignumber.js/bignumber'
import { useWallet } from 'use-wallet'
import { Card, CardHeader } from './Common'
import Button from '../../../components/Button'
import { useWithdrawal, useProfitWithdrawLimit, useWithdrawApplication, useUserBaseProfit, useUserExpProfit, useBinaryInfo, useBasicInfo } from '../hooks/useFinance'
import { onWithdraw, onHarvest, onCompound, onApplyBinaryReward } from '../hooks/finance'
import { getDisplayBalance } from '../../../utils/formatBalance'
import formatDuration from '../../../utils/formatDuration'

const Deposit: React.FC = () => {
  const { currentBinaryDBT, currentTitleDBT, currentMegaDBT } = useBinaryInfo();
  const { currentInterest, currentWithdrawable } = useBasicInfo();
  const { unlock, locked } = useWithdrawal();

  const currentInterestBN = new BigNumber(currentInterest);
  const currentWithdrawableBN = new BigNumber(currentWithdrawable);
  const currentBinaryDBTBN = new BigNumber(currentBinaryDBT);
  const currentTitleDBTBN = new BigNumber(currentTitleDBT);
  const currentMegaDBTBN = new BigNumber(currentMegaDBT);
  const unlockBN = new BigNumber(unlock);
  const lockedBN = new BigNumber(locked);

  const profitWithdrawLimit = useProfitWithdrawLimit()
  const { amount: baseAmount, duration: baseDuration } = useUserBaseProfit()
  const { amount: expAmount, duration: expDuration } = useUserExpProfit()
  const { account, ethereum } = useWallet<provider>()
  const { withdrawingAmount, withdrawingCountdown } = useWithdrawApplication()

  console.log('baseDuration', baseDuration, expDuration)

  const baseAmountBN = new BigNumber(baseAmount)
  const expAmountBN = new BigNumber(expAmount)
  const profitWithdrawLimitBN = new BigNumber(profitWithdrawLimit)
  const withdrawingAmountBN = new BigNumber(withdrawingAmount)

  const baseDurationNumber = baseDuration ? parseInt(baseDuration) : null
  const baseDurationString = baseDurationNumber ? formatDuration(baseDurationNumber) : '-'
  const expDurationNumber = baseDuration ? parseInt(expDuration) : null
  const expDurationString = expDurationNumber ? formatDuration(expDurationNumber) : '-'

  const withdrawingCountdownNumber = withdrawingCountdown ? parseInt(withdrawingCountdown) : null
  const countDownString = withdrawingCountdownNumber ? formatDuration(withdrawingCountdownNumber) : '-'

  const dbtMultiple = new BigNumber(0.4);

  return (
    <Card className="flex flex-col justify-center items-center text-center divide-x">
      <div className="flex-1 flex flex-col justify-start items-center py-2 w-full">
        <CardHeader>Current Earnings</CardHeader>
        <div className="font-bold text-primary">
          {currentWithdrawableBN.isNaN()
            ? "0"
            : getDisplayBalance(currentWithdrawableBN)}{" "}
          DBT
        </div>
        <p className="text-xs">Withdrawable Amount (Principal + Rewards)</p>
        <div className="text-xs flex flex-row justify-center items-start w-full">
          <div className="pr-2 flex-1 text-right">Basic Compensation:</div>
          <div className="flex-1 text-left">
            {currentInterestBN.isNaN()
              ? "0"
              : getDisplayBalance(currentInterestBN)}{" "}
            DBT
          </div>
        </div>
        <div className="text-xs flex flex-row justify-center items-start w-full">
          <div className="pr-2 flex-1 text-right">Binary System Rewards:</div>
          <div className="flex-1 text-left">
            {currentBinaryDBTBN.isNaN()
              ? "0"
              : getDisplayBalance(currentBinaryDBTBN)}{" "}
            DBT
          </div>
        </div>
        <div className="text-xs flex flex-row justify-center items-start w-full">
          <div className="pr-2 flex-1 text-right">Mega Match Rewards:</div>
          <div className="flex-1 text-left">
            {currentMegaDBTBN.isNaN()
              ? "0"
              : getDisplayBalance(currentMegaDBTBN)}{" "}
            DBT
          </div>
        </div>
        <div className="text-xs flex flex-row justify-center items-start w-full">
          <div className="pr-2 flex-1 text-right">Title Rewards:</div>
          <div className="flex-1 text-left">
            {currentTitleDBTBN.isNaN()
              ? "0"
              : getDisplayBalance(currentTitleDBTBN)}{" "}
            DBT
          </div>
        </div>
      </div>

      <div
        className="flex flex-row text-xs w-full py-2 divide-y"
        style={{ borderTop: "1px solid #eeeeee" }}
      >
        <div className="flex-1 flex flex-col justify-start items-center">
          <div>Click at the beginning of the month to calculate the previous month's rewards</div>
          <Button
            // disabled={baseDuration}
            onClick={() => onApplyBinaryReward(ethereum, account)}
          >
            Calculate Rewards
          </Button>
        </div>
        <div className="flex-1 flex flex-col justify-start items-center">
          <div>Compound your token without waiting</div>
          <Button
            // disabled={baseDuration}
            onClick={() => onCompound(ethereum, account)}
          >
            Compound
          </Button>
        </div>
      </div>
      <div
        className="flex flex-row text-xs w-full py-2 divide-y"
        style={{ borderTop: "1px solid #eeeeee" }}
      >
        <div className="flex-1 flex flex-col justify-start items-center">
          <div>Lock period: 240 hours</div>
          <div>Exit fee: 10%</div>
          <Button
            // disabled={baseDuration}
            onClick={() => onWithdraw(ethereum, account, currentWithdrawable)}
          >
            Withdraw DBT
          </Button>
        </div>
        <div className="flex-1 flex flex-col justify-center items-center">
          <div>
            Harvest your token
          </div>
          <div>Unlock: {unlockBN.isNaN()
            ? "0"
            : getDisplayBalance(unlockBN)}{" "}
          DBT</div>
          <div>Locked: {lockedBN.isNaN()
            ? "0"
            : getDisplayBalance(lockedBN)}{" "}
          DBT</div>
          <Button
            className="flex flex-col justify-center items-center"
            disabled={
              !lockedBN.isGreaterThan(0)
            }
            onClick={() => onHarvest(ethereum, account)}
          >
            Harvest
          </Button>
        </div>
      </div>
    </Card>
  );
}

const StyledInput = styled.input`
  border: 1px solid #000000;
  outline: none;
  border-radius: 20px;
  padding: 2px 4px;
`

export default Deposit
