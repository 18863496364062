import React from 'react'
import styled from 'styled-components'
import { ReactComponent as ShieldImg } from '../../../assets/images/shield.svg'

const Features: React.FC = () => {
  return (
    <div id="investment" className="w-screen bg-white text-black pt-20 pb-16 px-4">
      <div className="container text-center">
        <div className="text-3xl font-bold mb-10 investment-title">UNI Investment Conditions</div>

        <Header>1: Deposit</Header>
        <Content>
          ①Deposit using a decentralized Ethereum wallet.Please use BSC network, about how to link to BSC network: <a href="https://academy.binance.com/en/articles/how-to-get-started-with-binance-smart-chain-bsc">How to Get Started with Binance Smart Chain (BSC)</a><br />
          ②The deposit currency is BEP20 USDT. You will swap USDT to DBT(Exchange rate: 1USDT=0.4DBT).<br />
          ③The principal plan is set in five stages. The daily basic rate of return varies depending.<br />

          <table className="table w-full border border-gray-400 divide-y divide-gray-400">
            <thead>
              <tr>
                <th>Stage</th>
                <th>USDT</th>
                <th>Basic Reward Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>500 ~ 5000</td>
                <td>0.2%</td>
              </tr>
              <tr>
                <td>2</td>
                <td>5000 ~ 10000</td>
                <td>0.25%</td>
              </tr>
              <tr>
                <td>3</td>
                <td>10000 ~ 50000</td>
                <td>0.3%</td>
              </tr>
              <tr>
                <td>4</td>
                <td>50000 ~ 100000</td>
                <td>0.35%</td>
              </tr>
              <tr>
                <td>5</td>
                <td>≥100000</td>
                <td>0.4%</td>
              </tr>
            </tbody>
          </table>
        </Content>

        <Header>2: Basic Reward</Header>
        <Content>
          Daily Basic Reward: Principal * Basic Reward Rate<br />
          It will be distributed at 0:00(GMT)every day.<br />
          Basic reward will lock for 6month from first time you deposit.<br />
        </Content>

        <Header>3: Referral reward (shot deposit each time you refer)</Header>
        <Content>
          After depositing, a referral link will even be issued.<br />
          When a referral person makes a deposit, 10% of the deposit amount will be distributed as a referral reward.<br />
          Referral reward will lock for 3month from you invite people.<br />
        </Content>

        <Header>4: Uni-level reward (Basic rewards of the referee is the dividend)</Header>
        <Content>
          Daily Basic Reward: Principal * Basic Reward Rate <br />
          It will be distributed every time your referee deposit or withdraw.<br />
          Uni-level will lock for 3month from you invite people.<br />


          <table className="table w-full border border-gray-400 divide-y divide-gray-400 text-xs">
            <tbody>
              <tr>
                <th className="px-1 text-center">Stage</th>
                <th className="px-1 text-center">Stage1</th>
                <th className="px-1 text-center">Stage2</th>
                <th className="px-1 text-center">Stage3</th>
                <th className="px-1 text-center">Stage4</th>
                <th className="px-1 text-center">Stage5</th>
              </tr>
              {
                [
                  [5, 8, 11, 13, 15],
                  [3, 5, 9, 11, 13],
                  [2, 3, 6, 8, 10],
                  [1, 2, 3, 5, 7],
                  [1, 1, 2, 3, 5],
                  [1, 1, 1, 2, 3],
                  [1, 1, 1, 1, 2],
                  [1, 1, 1, 1, 1],
                  [1, 1, 1, 1, 1],
                  [1, 1, 1, 1, 1]
                ].map((i, index) => (
                  <tr key={`${index}`}>
                    <td className="text-center">Level {index + 1}</td>
                    <td className="text-center">{i[0]}%</td>
                    <td className="text-center">{i[1]}%</td>
                    <td className="text-center">{i[2]}%</td>
                    <td className="text-center">{i[3]}%</td>
                    <td className="text-center">{i[4]}%</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <br />

          <table className="table w-full border border-gray-400 divide-y divide-gray-400 text-xs">
            <thead>
              <tr>
                <th className="px-2 text-center">The number of the referrer</th>
                <th className="px-2 text-center">The level of uni-level reward</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-2 text-center">1</td>
                <td className="px-2 text-center">Level 3</td>
              </tr>
              <tr>
                <td className="px-2 text-center">3</td>
                <td className="px-2 text-center">Level 5</td>
              </tr>
              <tr>
                <td className="px-2 text-center">5</td>
                <td className="px-2 text-center">Level 10</td>
              </tr>
            </tbody>
          </table>
        </Content>

        <Header>5: Withdrawal</Header>
        <Content>
          ① Withdrawal fee 10%<br />
          ② You can withdraw within 240 hours after applying for withdrawal.<br />
          ③ You can withdraw the principal as it is, but you can withdraw up to 500% of the principal in the profit portion.<br />
        </Content>
      </div>
      <div className="container text-center">
        <div className="text-3xl font-bold mb-10 mt-10 investment-title">Binary Investment Conditions</div>

        <Header>1: DBCG DeFi Basic compensation</Header>
        <Content>
          ① BUY DBT(Digital bank point)by USDT.<br />
          ② Exchange rate: 1USDT=0.4DBT.<br />
          ③ Target compensation of DBCG DeFi is 0.2%-0.4% per day, 6%-12% per month.<br />
          ④ Basic compensation is sent back by DBT.<br />
          ⑤ Principal and basic compensation is locked for 6 months and 6 month later it will be shares of LLC(Limited liability company).

          <table className="table w-full border border-gray-400 divide-y divide-gray-400">
            <thead>
              <tr>
                <th>Stage</th>
                <th>USDT</th>
                <th>Basic Reward Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>500 ~ 5000</td>
                <td>0.2%</td>
              </tr>
              <tr>
                <td>2</td>
                <td>5000 ~ 10000</td>
                <td>0.25%</td>
              </tr>
              <tr>
                <td>3</td>
                <td>10000 ~ 50000</td>
                <td>0.3%</td>
              </tr>
              <tr>
                <td>4</td>
                <td>50000 ~ 100000</td>
                <td>0.35%</td>
              </tr>
              <tr>
                <td>5</td>
                <td>≥100000</td>
                <td>0.4%</td>
              </tr>
            </tbody>
          </table>
        </Content>

        <Header>2: There are 4 reward system of DBCG DeFi for binary system</Header>
        <Content>
          It is paid by DBT.
        </Content>

        <Header>(1) Direct referral reward</Header>
        <Content>
          ① 10% of referee(A) purchase of DBT.<br />
          EX) If A buy USDT 10,000 equivalent of DBT, referrer gets USDT 1,000 equivalent of DBT.<br />
          ② There is no lock up for Direct referral reward.<br />
          You can withdraw it by USDT.<br />
          ③ You can send Direct referral reward to your referee to use as initial purchase of DBT.
        </Content>

        <Header>(2) Binary system reward</Header>
        <Content>
          Compare left and wright total purchase amount.<br />
          Reward is 10-20% of smaller group.<br />
          10-20% is decided by your own investment amount described as below.<br />
          <table className="table w-full border border-gray-400 divide-y divide-gray-400">
            <thead>
              <tr>
                <th>Stage</th>
                <th>USDT</th>
                <th>Basic Reward Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>500 ~ 5000</td>
                <td>10%</td>
              </tr>
              <tr>
                <td>2</td>
                <td>5000 ~ 10000</td>
                <td>13%</td>
              </tr>
              <tr>
                <td>3</td>
                <td>10000 ~ 50000</td>
                <td>15%</td>
              </tr>
              <tr>
                <td>4</td>
                <td>50000 ~ 100000</td>
                <td>17%</td>
              </tr>
              <tr>
                <td>5</td>
                <td>≥100000</td>
                <td>20%</td>
              </tr>
            </tbody>
          </table>
          ⇒3 month lock up <br />
        </Content>

        <Header>(3) Mega match reward</Header>
        <Content>
          If your direct referee gets rewards, you can get rewards as per described below.
          <table className="table w-full border border-gray-400 divide-y divide-gray-400">
            <thead>
              <tr>
                <th className="px-2 text-center">Level</th>
                <th className="px-2 text-center">Mega match reward</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-2 text-center">Level 1</td>
                <td className="px-2 text-center">3%</td>
              </tr>
              <tr>
                <td className="px-2 text-center">Level 2</td>
                <td className="px-2 text-center">2%</td>
              </tr>
              <tr>
                <td className="px-2 text-center">Level 3</td>
                <td className="px-2 text-center">1%</td>
              </tr>
            </tbody>
          </table>
        </Content>

        <Header>(4) Title reward</Header>
        <Content>
          If total amount of smaller group exceeds following amount, you can get title rewards as per described below.
          <table className="table w-full border border-gray-400 divide-y divide-gray-400">
            <thead>
              <tr>
                <th className="px-2 text-center">Amount</th>
                <th className="px-2 text-center">Title reward</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-2 text-center">5,000,000</td>
                <td className="px-2 text-center">3%</td>
              </tr>
              <tr>
                <td className="px-2 text-center">10,000,000</td>
                <td className="px-2 text-center">3%</td>
              </tr>
              <tr>
                <td className="px-2 text-center">25,000,000</td>
                <td className="px-2 text-center">2%</td>
              </tr>
              <tr>
                <td className="px-2 text-center">50,000,000</td>
                <td className="px-2 text-center">1%</td>
              </tr>
              <tr>
                <td className="px-2 text-center">100,000,000</td>
                <td className="px-2 text-center">1%</td>
              </tr>
            </tbody>
          </table>
          * Mega match reward and Title reward will be calcuted every end of month.If you do not meet the minimum reward requirements, it will be set to ZERO when month begins.<br />
          * You can click "Calculate Rewards" button to calcute reward of last month.
        </Content>

        <Header>3: Withdrawal</Header>
        <Content>
          ① Withdrawal fee 10%<br />
          ② You can withdraw within 240 hours after applying for withdrawal.<br />
          ③ You can withdraw the principal as it is, but you can withdraw up to 40% of the principal in the profit portion.<br />
        </Content>

        <Header>4: You can invest your earned rewards to your own new investment by click "Compound" button.</Header>

        <Header>Attention</Header>
        <Content>
          * You can chose to participate in Binary Investment or UNI Investment. You can also participate in Both of them. But your referral and reward will be calculated independently.
        </Content>
      </div>
    </div>
  )
}

const Header = styled.div`
  text-align: left;
  margin: 20px auto 0;
  max-width: 600px;
  font-weight: bold;
`

const Content = styled.div`
  text-align: left;
  white-space: pre-line;
  max-width: 600px;
  margin: auto;
`

export default Features
