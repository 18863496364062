import React from 'react'
import styled from 'styled-components'
import logoIcon from '../../assets/images/logo.svg'

const Footbar: React.FC = () => (
  <div className="pt-40 container px-4">
    <Footer className="flex flex-row justify-between items-center text-sm footer py-10">
      <a href="#" className="flex flex-row justify-between items-center">
        <img src={logoIcon} alt="" className="h-8 mr-2" style={{ transform: 'rotateZ(45deg)' }} />
        <span className="text-sm font-bold">DBCG DEFI</span>
      </a>
      <div className="flex flex-row justify-end items-center flex-1">
        ©{new Date().getFullYear()} DBCG DEFI
      </div>
    </Footer>
  </div>
)

const Footer = styled.footer`
  border-top: 1px solid #333333;
`

export default Footbar
