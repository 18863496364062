import React, { useEffect, useState } from 'react'
import Page from '../../components/Page'
import Deposits from './components/Deposits'
import { useWallet } from 'use-wallet'
import Button from '../../components/Button'
import { onWithdrawCurrencyByOwner, onWithdrawTokenByOwner } from './hooks/finance'
import { provider } from 'web3-core'

const Admin: React.FC = () => {
  const { connect } = useWallet()
  const { account, ethereum } = useWallet<provider>()
  useEffect(() => {
    connect('injected')
  }, [])

  const [currencyAmount, setCurrencyAmount] = useState('0')
  const [tokenAmount, setTokenAmount] = useState('0')

  return (
    <Page>
      <div className="mt-24" />
      <div className="mb-4 md:flex flex-row justify-between items-center text-black">
        <div className="flex flex-col items-center justify-between md:mr-4">
          <input className="pl-2 pr-2" type="number" value={currencyAmount} onChange={(e) => setCurrencyAmount(e.target.value)} />
          <Button className="mt-2"
            onClick={() => onWithdrawCurrencyByOwner(ethereum, account, currencyAmount)}>
            Withdraw USDT
          </Button>
        </div>
        <div className="flex flex-col items-center justify-between md:ml-4 mt-5 md:mt-0">
          <input className="pl-2 pr-2" type="number" value={tokenAmount} onChange={(e) => setTokenAmount(e.target.value)} />
          <Button className="mt-2"
            onClick={() => onWithdrawTokenByOwner(ethereum, account, tokenAmount)}>
            Withdraw DBT
          </Button>
        </div>
      </div>
      <Deposits />
    </Page>
  )
}

export default Admin
