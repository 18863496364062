import React, { useEffect } from 'react'
import styled from 'styled-components'
import Page from '../../components/Page'
import Deposit from './components/Deposit'
import Earnings from './components/Earnings'
import ReferrerRewards from './components/ReferrerRewards'
import Revenues from './components/Revenues'
import MyReferral from './components/MyReferral'
import RevenueHistory from './components/RevenueHistory'
import { useWallet } from 'use-wallet'

const Defi: React.FC = () => {
  const { connect } = useWallet()
  useEffect(() => {
    connect('injected')
  }, [])

  return (
    <Page>
      <div className="mt-24" />
      <Deposit />
      <Revenues />
      <ReferrerRewards />
      <Earnings />
      <MyReferral />
      {/*<RevenueHistory />*/}
    </Page>
  );
}

export default Defi
