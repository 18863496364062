import React from 'react'
import styled from 'styled-components'
import { Card, CardHeader } from './Common'
import Button from '../../../components/Button'
import { useRevenues } from '../hooks/useFinance'
import BigNumber from 'bignumber.js'
import { getDisplayBalance } from '../../../utils/formatBalance'

const Deposit: React.FC = () => {
  const { totalProfit, uniLevelRewards, referralRewards } = useRevenues()
  const totalProfitBN = new BigNumber(totalProfit)
  const uniLevelRewardsBN = new BigNumber(uniLevelRewards)
  const referralRewardsBN = new BigNumber(referralRewards)
  const dbtMultiple = new BigNumber(1)

  return (
    <Card className="flex flex-col justify-center items-center text-center py-2">
      <CardHeader>Total Revenues</CardHeader>
      <div className="flex flex-row text-xs w-full my-2">
        <div className="flex-1" style={{ borderRight: '1px solid #eeeeee' }}>
          <div className="opacity-75">Basic Compensation</div>
          <div>{referralRewardsBN.isNaN() ? '0' : getDisplayBalance(totalProfitBN.minus(uniLevelRewards).minus(referralRewards).times(dbtMultiple))} DBT</div>
          <div className="opacity-50 text-xs">
            Locked for 6 months
          </div>
        </div>
        <div className="flex-1" style={{ borderRight: '1px solid #eeeeee' }}>
          <div className="opacity-75">Uni-level Rewards</div>
          <div>{uniLevelRewardsBN.isNaN() ? '0' : getDisplayBalance(uniLevelRewardsBN.times(dbtMultiple))} DBT</div>
          <div className="opacity-50 text-xs">
            Locked for 3 months
          </div>
        </div>
        <div className="flex-1">
          <div className="opacity-75">Referral Rewards</div>
          <div>{referralRewardsBN.isNaN() ? '0' : getDisplayBalance(referralRewardsBN.times(dbtMultiple))} DBT</div>
          <div className="opacity-50 text-xs">
            Locked for 3 months
          </div>
        </div>
      </div>
    </Card>
  )
}

export default Deposit
