import React, { useCallback, useState } from 'react'
import BigNumber from 'bignumber.js/bignumber'
import { useWallet } from 'use-wallet'
import { provider } from 'web3-core'
import { useReferee, useReferrals } from '../hooks/useInvite'
import { useTotalDeposit } from '../hooks/useFinance'
import { Card, CardHeader } from './Common'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const Deposit: React.FC = () => {
  const [copied, setCopied] = useState(false)
  const { account } = useWallet<provider>()
  const referee = useReferee()
  const referrals = useReferrals()

  const isReferred = referee && parseInt(referee) !== 0

  const totalDeposit = useTotalDeposit()
  const isDeposited = totalDeposit.isGreaterThan(new BigNumber(0))

  const link = `${window.origin}/?binary=${account}`
  const onCopy = useCallback(() => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }, [setCopied])

  return (
    <Card className="flex flex-col justify-center items-center text-center py-2">
      <CardHeader>My Referral</CardHeader>
      <div className="flex-1 flex flex-col justify-start items-center pb-2">
        {
          isDeposited && account &&
          <CopyToClipboard text={link} onCopy={onCopy}
            className="text-xs underline cursor-pointer">
            <span>{copied ? 'Copied!' : 'Copy invitation link'}</span>
          </CopyToClipboard>
        }
        <div className="text-xs">Number of direct referrals: {referrals ? referrals.length : '-'}</div>
      </div>
      {
        referrals && referrals.map((i, index) => (
          <div key={`referral_${index}`} className="w-full px-10 text-xs">{i}</div>
        ))
      }
    </Card>
  )
}

export default Deposit
