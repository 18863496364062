import React, { useCallback } from 'react'
import { useWallet } from 'use-wallet'
import useTokenBalance from '../../../hooks/useTokenBalance'
import { getSushiAddress } from '../../../sushi/utils'
import Button from '../../Button'
import Modal, { ModalProps } from '../../Modal'
import ModalTitle from '../../ModalTitle'
import Spacer from '../../Spacer'

const AccountModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { account, reset } = useWallet()

  const handleSignOutClick = useCallback(() => {
    onDismiss()
    reset()
  }, [onDismiss, reset])

  return (
    <Modal>
      <ModalTitle text="My Account" />
      <div className="flex flex-col pb-10 w-48 justify-center items-center">
        <Spacer />
        <Button
          href={`https://etherscan.io/address/${account}`}
          text="View on Etherscan"
          type="primary"
        />
        <Spacer />
        <Button
          onClick={handleSignOutClick}
          text="Sign out"
          type="secondary"
        />
      </div>
    </Modal>
  )
}

export default AccountModal
