import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { useWallet } from 'use-wallet'
import { provider } from 'web3-core'
import { getBalance } from '../../../utils/erc20'
import Lend from '../../../libs/lend'
import useBlock from '../../../hooks/useBlock'
import useModal from '../../../hooks/useModal'
import Button from '../../Button'
import WalletProviderModal from '../../WalletProviderModal'
import AccountModal from './AccountModal'
import useTokenBalance from '../../../hooks/eth/useTokenBalance'
import { getBalanceNumber } from '../../../utils/formatBalance'
import { address, constants } from '../../../libs/lend/constants'
import { getNetNameWithChainId } from '../../../libs/lend/util'

interface AccountButtonProps {
}

const AccountButton: React.FC<AccountButtonProps> = (props) => {
  const [onPresentAccountModal] = useModal(<AccountModal />)
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />)
  const history = useHistory()

  const { account, chainId } = useWallet<provider>()
  console.log('chainId', chainId)

  return account ? (
    <div className="flex flex-row justify-between items-center account-button">
      <Button>
        {`${account.slice(0, 4)}...${account.slice(-4)}`}
      </Button>
    </div>
  ) : (
    <div className="flex flex-row justify-between items-center account-button">
      <Button onClick={onPresentWalletProviderModal}>
        Unlock Wallet
    </Button>
    </div>
  )
}

export default AccountButton
