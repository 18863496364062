import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { useWallet, UseWalletProvider } from 'use-wallet'
import TopBar from './components/TopBar'
import ModalsProvider from './contexts/Modals'
import TransactionProvider from './contexts/Transactions'
import theme from './theme'
import Home from './views/Home'
import Defi from './views/Defi'
import Defi2 from './views/Defi2'
import Defi3 from './views/Defi3'
import Admin from './views/Admin'
import AdminDeposits from './views/AdminDeposits'
import BinaryAdmin from './views/BinaryAdmin'
import BinaryAdminDeposits from './views/BinaryAdminDeposits'

const App: React.FC = () => {

  return (
    <Providers>
      <Router>
        <TopBar />
        <Switch>
          <Route path="/" exact><Home /></Route>
          <Route path="/uni" exact><Defi /></Route>
          <Route path="/binary" exact><Defi2 /></Route>
          <Route path="/proxy" exact><Defi3 /></Route>
          <Route path="/admin/uni" exact><Admin /></Route>
          <Route path="/admin-deposits/uni" exact><AdminDeposits /></Route>
          <Route path="/admin/binary" exact><BinaryAdmin /></Route>
          <Route path="/admin-deposits/binary" exact><BinaryAdminDeposits /></Route>
        </Switch>
      </Router>
    </Providers>
  )
}

const Providers: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <UseWalletProvider
        chainId={56}
        connectors={{
          walletconnect: { rpcUrl: 'https://mainnet.infura.io/v3/dc8177ee9577424bbaef4b366dedef99' }
        }}
      >
        <TransactionProvider>
          <ModalsProvider>{children}</ModalsProvider>
        </TransactionProvider>
      </UseWalletProvider>
    </ThemeProvider>
  )
}

export default App
