import React, { useEffect } from 'react'
import Page from '../../components/Page'
import Deposit from './components/Deposit'
import Revenues from './components/Revenues'
import MyReferral from './components/MyReferral'
import { useWallet } from 'use-wallet'

const Defi: React.FC = () => {
  const { connect } = useWallet()
  useEffect(() => {
    connect('injected')
  }, [])

  return (
    <Page>
      <div className="mt-24" />
      <Deposit />
      <Revenues />
      <MyReferral />
    </Page>
  );
}

export default Defi
