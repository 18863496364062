import styled from 'styled-components'

export const Card = styled.div`
  width: 900px;
  margin-bottom: 20px;
  border-radius: 4px;
  background: white;
  color: black;
  box-sizing: border-box;

  @media (max-width: 640px) {
    width: 100%;
  }
`

export const CardHeader = styled.div`
  font-weight: bold;
`
