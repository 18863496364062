import React from 'react'
import styled from 'styled-components'
import Footbar from '../Footbar'

const Page: React.FC = ({ children }) => (
  <StyledPage>
    <StyledMain>{children}</StyledMain>
    <Footbar />
  </StyledPage>
)

const StyledPage = styled.div`  
@media (max-width: 640px) {
  padding-left: 10px;
  padding-right: 10px;
}
`

const StyledMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${(props) => props.theme.topBarSize * 2}px);
`

export default Page
