import React from "react";
import { Card, CardHeader } from "./Common";
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const Deposit: React.FC = () => {

  return (
    <Card className="flex flex-col justify-center items-center text-center py-2 pt-5 pb-5">
      <CardHeader>Invest UNI and BINARY at the same time</CardHeader>
      <div className="lg:flex">
        <NavLink exact to="/uni">
          <StartButton className="mt-8">CHECK UNI REWARDS</StartButton>
        </NavLink>
        <NavLink exact to="/binary">
          <StartButton className="mt-8">CHECK BINARY REWARDS</StartButton>
        </NavLink>
      </div>
    </Card>
  );
};

const StartButton = styled.div`
  background: #F0B90C;
  height: 40px;
  width: 200px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: bold;
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
`

export default Deposit;
