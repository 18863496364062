import React from 'react'
import styled from 'styled-components'
import { ReactComponent as ShieldImg } from '../../../assets/images/shield.svg'
import { ReactComponent as StarImg } from '../../../assets/images/star.svg'
import { ReactComponent as FindImg } from '../../../assets/images/find.svg'

const Features: React.FC = () => {
  return (
    <div id="features" className="w-screen bg-white text-black pt-20 pb-16 text-center">
      <div className="container">
        <div className="text-3xl font-bold mb-16">Features</div>

        <div className="flex flex-col lg:flex-row justify-center items-start flex-wrap">
          <div className="flex flex-col justify-center items-center text-center pb-10 px-6 lg:w-1/3">
            <ShieldImg className="w-12 h-12 mb-1 text-primary" />
            <div className="text-black my-2 font-bold text-xl">Code is Law</div>
            <div className="text-center text-md-left pr-md-2">
              In the world of smart contracts, it is not the nation or the company that decides the rules. This is the code. Even the producer is not leath possible to tamper with the product.
            </div>
          </div>
          <div className="flex flex-col justify-center items-center text-center pb-10 px-6 lg:w-1/3">
            <StarImg className="w-12 h-12 mb-1 text-primary" />
            <div className="text-black my-2 font-bold text-xl">Market is Growing</div>
            <div className="text-center text-md-left pr-md-2">
              At this very moment, DeFi's market continues to grow with technological innovation. DeFi will be a game changer that will transform the traditional financial system.
            </div>
          </div>
          <div className="flex flex-col justify-center items-center text-center pb-10 px-6 lg:w-1/3">
            <FindImg className="w-12 h-12 mb-1 text-primary" />
            <div className="text-black my-2 font-bold text-xl">Easy to Invest</div>
            <div className="text-center text-md-left pr-md-2">
              Anyone with one smartphone can easily access it from their wallet. There are people all over the world who have not been able to use financial services until now and do not even have a bank account.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
