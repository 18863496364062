import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Image1 } from '../../../assets/images/home-icon-token-1.svg'
import { ReactComponent as Image2 } from '../../../assets/images/home-icon-token-3.svg'
import { ReactComponent as Image3 } from '../../../assets/images/home-icon-token-2.svg'

const HowToJoin: React.FC = () => {
  return (
    <div id="howtojoin" className="container py-20 justify-center items-start text-center">
      <div className="text-3xl font-bold mb-16">How to Join</div>

      <div className="flex flex-col lg:flex-row">
        <div className="lg:w-1/3 px-10 flex flex-col items-center">
          <Image1 className="w-24 h-24 mb-1 text-primary" />
          <div className="font-bold text-xl mb-2">Step1: Connect to ETH Wallet</div>
          <div className="opacity-50">
            Connect to your own ETH Wallet. Prepare a Web Wallet with browser extensions such as "MetaMask", or "Imtoken". Wallet that can be used on a smartphone.
          </div>
        </div>

        <div className="lg:w-1/3 px-10 flex flex-col items-center">
          <Image2 className="w-24 h-24 mb-1 text-primary" />
          <div className="font-bold text-xl mb-2">Step2: USDT Transfer</div>
          <div className="opacity-50">
            When you tap the "Deposit" button on the investment page, the first time you call the DBCG DEFI smart contract. If you tap it again, you can transfer the USDT to the smart contract address for the second time.
          </div>
        </div>

        <div className="lg:w-1/3 px-10 flex flex-col items-center">
          <Image3 className="w-24 h-24 mb-1 text-primary" />
          <div className="font-bold text-xl mb-2">Step3: Revenue</div>
          <div className="opacity-50">
            You can get a stable profit according to the amount of investment every day. Revenue can be without a deposit of up to 500% of the deposit. Affiliates will further expand your earnings.
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowToJoin
