import Web3 from 'web3'
import { provider } from 'web3-core'
import { AbiItem } from 'web3-utils'
import FinanceABI from "../../Defi/hooks/finance.json";
import { FinanceContractAddress } from '../../Defi/hooks/constants'

export const getInviteContract = (provider: provider) => {
  const web3 = new Web3(provider)
  return new web3.eth.Contract(
    (FinanceABI as unknown) as AbiItem,
    FinanceContractAddress
  )
}

export const getReferee = async (
  provider: provider,
  userAddress: string
): Promise<any> => {
  try {
    const lpContract = getInviteContract(provider)
    const result = await lpContract.methods
      .getReferee(userAddress)
      .call()
    return result
  } catch (e: any) {
    return null
  }
}

export const getReferrals = async (
  provider: provider,
  userAddress: string
): Promise<any> => {
  try {
    const lpContract = getInviteContract(provider)
    const result = await lpContract.methods
      .getReferrals(userAddress)
      .call()
    return result
  } catch (e: any) {
    return null
  }
}

export const onBindReferee = async (
  provider: provider,
  account: string,
  referee: string
): Promise<any> => {
  try {
    const lpContract = getInviteContract(provider)
    await lpContract.methods
      .bind(referee)
      .send({ from: account })
      .on('transactionHash', (tx) => {
        console.log(tx)
        return tx.transactionHash
      })
  } catch (e: any) {
    return null
  }
}
