import React, { useCallback, useEffect, useState } from 'react'
import { useWallet } from 'use-wallet'
import { provider } from 'web3-core'
import { getUserAddressByIndex } from '../hooks/finance'
import { getTotalDeposit, getBinaryInfo, getBasicInfo } from '../../Defi2/hooks/finance'
import { getDisplayBalance } from '../../../utils/formatBalance'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { getReferee, getReferrals } from '../../Defi2/hooks/invite'
import formatDuration from '../../../utils/formatDuration'
import { FinanceContractAddress } from '../../Defi2/hooks/constants'

const DepositRow: ({ index: number }) => JSX.Element = ({ index }) => {
  const { account, ethereum } = useWallet<provider>()
  const [showDetail, setShowDetail] = useState(false)

  const [address, setAddress] = useState('-')
  const [referee, setReferee] = useState('-')
  const [totalDeposit, setTotalDeposit] = useState('-')

  const [binaryInfo, setBinaryInfo] = useState(0)
  const [binaryInfo2, setBinaryInfo2] = useState(0)
  const [binaryInfo3, setBinaryInfo3] = useState(0)
  const [basicInfo, setBasicInfo] = useState('-')
  const [referrals, setReferrals] = useState(null)

  const totalDepositBN = new BigNumber(totalDeposit)
  const binaryInfoBN = new BigNumber(binaryInfo)
  const binaryInfo2BN = new BigNumber(binaryInfo2)
  const binaryInfo3BN = new BigNumber(binaryInfo3)
  const basicInfoBN = new BigNumber(basicInfo)

  useEffect(() => {
    getUserAddressByIndex(ethereum, index).then(value => {
      setAddress(value)
      getReferee(ethereum, value).then(value2 => {
        setReferee(value2)
      })
      getTotalDeposit(ethereum, value).then(value2 => {
        setTotalDeposit(value2)
      })
      getReferrals(ethereum, value).then(value2 => {
        setReferrals(value2)
      })
      getBinaryInfo(ethereum, value).then(value2 => {
        setBinaryInfo(value2[0])
        setBinaryInfo2(value2[1])
        setBinaryInfo2(value2[3])
      })
      getBasicInfo(ethereum, value).then(value2 => {
        setBasicInfo(value2[0])
      })
    })
  }, [account, ethereum])

  return (
    <Row className="flex flex-row justify-between items-center w-full px-10">
      <div className="w-1/6 px-2 text-left break-words">
        <div>{address}</div>
        <div>Referee: {referee == FinanceContractAddress ? '-' : referee}</div>
      </div>
      <div className="w-1/6 px-2 text-center">
        <div>{getDisplayBalance(totalDepositBN.times(0.4))} DBT</div>
      </div>
      <div className="w-1/6 px-2 text-center">
        <div>{getDisplayBalance(basicInfoBN.times(0.4))} DBT</div>
      </div>
      <div className="w-1/6 px-2 text-center">
        <div>{getDisplayBalance(binaryInfoBN.times(0.4))} DBT</div>
      </div>
      <div className="w-1/6 px-2 text-center">
        <div>{getDisplayBalance(binaryInfo2BN.times(0.4))} DBT</div>
      </div>
      <div className="w-1/6 px-2 text-center">
        <div>{getDisplayBalance(binaryInfo3BN.times(0.4))} DBT</div>
      </div>

      <div className="w-1/6 px-2 text-center">
        {referrals ? referrals.length : '-'}
        {referrals && referrals.length > 0 && (<span className="ml-1 cursor-pointer underline" onClick={() => setShowDetail(!showDetail)}>(Detail)</span>)}
        {showDetail && (
          referrals.map(i => <div>{i}</div>)
        )}
      </div>
    </Row>
  )
}

const Row = styled.div`
  border-top: 1px solid #cccccc;
  padding: 20px;
`

export default DepositRow
