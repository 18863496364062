import React from 'react'
import styled from 'styled-components'
import Page from '../../components/Page'
import Banner from './components/Banner'
import Features from './components/Features'
import Investment from './components/Investment'
import Roadmap from './components/Roadmap'
import HowToJoin from './components/HowToJoin'
import Footbar from '../../components/Footbar'

const Home: React.FC = () => {
  return (
    <Page>
      <Banner />
      <Features />
      <HowToJoin />
      <Investment />
      <Roadmap />
    </Page>
  )
}

export default Home
