import React, { useState } from 'react'
import { provider } from 'web3-core'
import styled from 'styled-components'
import BigNumber from 'bignumber.js/bignumber'
import { useWallet } from 'use-wallet'
import { Card, CardHeader } from './Common'
import Button from '../../../components/Button'
import { useProfitWithdrawLimit, useWithdrawApplication, useUserBaseProfit, useUserExpProfit } from '../hooks/useFinance'
import { onWithdraw, onHarvest } from '../hooks/finance'
import { getDisplayBalance } from '../../../utils/formatBalance'
import formatDuration from '../../../utils/formatDuration'

const Deposit: React.FC = () => {
  const profitWithdrawLimit = useProfitWithdrawLimit()
  const { amount: baseAmount, duration: baseDuration } = useUserBaseProfit()
  const { amount: expAmount, duration: expDuration } = useUserExpProfit()
  const { account, ethereum } = useWallet<provider>()
  const { withdrawingAmount, withdrawingCountdown } = useWithdrawApplication()

  console.log('baseDuration', baseDuration, expDuration)

  const baseAmountBN = new BigNumber(baseAmount)
  const expAmountBN = new BigNumber(expAmount)
  const profitWithdrawLimitBN = new BigNumber(profitWithdrawLimit)
  const withdrawingAmountBN = new BigNumber(withdrawingAmount)

  const baseDurationNumber = baseDuration ? parseInt(baseDuration) : null
  const baseDurationString = baseDurationNumber ? formatDuration(baseDurationNumber) : '-'
  const expDurationNumber = baseDuration ? parseInt(expDuration) : null
  const expDurationString = expDurationNumber ? formatDuration(expDurationNumber) : '-'

  const withdrawingCountdownNumber = withdrawingCountdown ? parseInt(withdrawingCountdown) : null
  const countDownString = withdrawingCountdownNumber ? formatDuration(withdrawingCountdownNumber) : '-'

  const dbtMultiple = new BigNumber(1)

  return (
    <Card className="flex flex-col justify-center items-center text-center divide-x">
      <div className="flex-1 flex flex-col justify-start items-center py-2 w-full">
        <CardHeader>Current Earnings</CardHeader>
        <div className="font-bold text-primary">{getDisplayBalance(baseAmountBN.plus(expAmountBN).times(dbtMultiple))} DBT</div>
        <div className="text-xs flex flex-row justify-center items-start w-full">
          <div className="pr-2 flex-1 text-right">Basic Compensation:</div>
          <div className="flex-1 text-left">
            {getDisplayBalance(baseAmountBN.times(dbtMultiple))} DBT
            {baseDurationNumber ? <div>(unlocked after {baseDurationString})</div> : null}
          </div>
        </div>
        <div className="text-xs flex flex-row justify-center items-start w-full">
          <div className="pr-2 flex-1 text-right">Uni-level & Referral Rewards:</div>
          <div className="flex-1 text-left">
            {getDisplayBalance(expAmountBN.times(dbtMultiple))} DBT
            {expDurationNumber ? <div>(unlocked after {expDurationString})</div> : null}
          </div>
        </div>
        <div className="text-xs flex flex-row justify-center items-start w-full">
          <div className="pr-2 flex-1 text-right">Withdrawal Limit:</div>
          <div className="flex-1 text-left">
            {getDisplayBalance(profitWithdrawLimitBN.times(dbtMultiple))} DBT
          </div>
        </div>
      </div>

      <div className="flex flex-row text-xs w-full py-2 divide-y" style={{ borderTop: '1px solid #eeeeee' }}>
        <div className="flex-1 flex flex-col justify-start items-center">
          <div>Lock period: 240 hours</div>
          <div>Exit fee: 10%</div>
          <Button
            // disabled={baseDuration}
            onClick={() => onWithdraw(ethereum, account, false)}>
            Withdraw
          </Button>
        </div>
        <div className="flex-1 flex flex-col justify-center items-center">
          <div>Unlocking withdraw: {getDisplayBalance(withdrawingAmountBN.times(dbtMultiple))} DBT</div>
          <div>Unlocking countdown: {countDownString}</div>
          <Button
            className="flex flex-col justify-center items-center"
            disabled={(!withdrawingAmountBN.isGreaterThan(0)) || withdrawingCountdownNumber > 0}
            onClick={() => onHarvest(ethereum, account)}>
            Harvest
          </Button>
        </div>
      </div>
    </Card>
  )
}

const StyledInput = styled.input`
  border: 1px solid #000000;
  outline: none;
  border-radius: 20px;
  padding: 2px 4px;
`

export default Deposit
