import React from 'react'
import styled from 'styled-components'
import bgImg from '../../../assets/images/brilliant.png'
import { NavLink } from 'react-router-dom'

const Banner: React.FC = () => {
  return (
    <BannerContainer className="h-screen w-screen flex flex-col justify-center items-center text-center">
      <div className="text-4xl md:text-6xl leading-tight pt-10">
        DBCG DEFI
      </div>
      <div className="flex flex-col md:flex-row justify-center items-center opacity-50">
        Perform the best service by improving blockchain technology to the world!
      </div>

      <div className="lg:flex">
        <NavLink exact to="/proxy">
          <StartButton2 className="mt-8">START UNI & BINARY</StartButton2>
        </NavLink>
      </div>
      <div className="lg:flex">
        <NavLink exact to="/uni">
          <StartButton className="mt-8">START UNI</StartButton>
        </NavLink>
        <NavLink exact to="/binary">
          <StartButton className="mt-8">START BINARY</StartButton>
        </NavLink>
      </div>

    </BannerContainer>
  )
}

const BannerContainer = styled.div`
  background-image: url(${bgImg});
  background-repeat: repeat;
`

const StartButton = styled.div`
  background: #F0B90C;
  height: 50px;
  width: 200px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
`

const StartButton2 = styled.div`
  background: #F0B90C;
  height: 50px;
  width: 300px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
`

export default Banner
