import React from "react";
import styled from "styled-components";
import { Card, CardHeader } from "./Common";
import Button from "../../../components/Button";
import { useRevenues, useBinaryInfo, useReferralRewards, useBasicInfo } from "../hooks/useFinance";
import BigNumber from "bignumber.js";
import { getDisplayBalance } from "../../../utils/formatBalance";

const Deposit: React.FC = () => {
  const { totalProfit, uniLevelRewards } = useRevenues();
  const { binaryDBT, titleDBT, megaDBT } = useBinaryInfo();
  const { interest } = useBasicInfo();
  const { referralRewards } = useReferralRewards();
  const totalProfitBN = new BigNumber(totalProfit);
  const uniLevelRewardsBN = new BigNumber(uniLevelRewards);
  const referralRewardsBN = new BigNumber(referralRewards);

  const interestBN = new BigNumber(interest);
  const binaryDBTBN = new BigNumber(binaryDBT);
  const titleDBTBN = new BigNumber(titleDBT);
  const megaDBTBN = new BigNumber(megaDBT);

  return (
    <Card className="flex flex-col justify-center items-center text-center py-2">
      <CardHeader>Total Revenues</CardHeader>
      <div className="flex flex-row text-xs w-full my-2">
        <div className="flex-1" style={{ borderRight: "1px solid #eeeeee" }}>
          <div className="opacity-75">Basic Compensation</div>
          <div>
            {interestBN.isNaN()
              ? "0"
              : getDisplayBalance(interestBN)}{" "}
            DBT
          </div>
          <div className="opacity-50 text-xs">Locked for 6 months</div>
        </div>
        <div className="flex-1" style={{ borderRight: "1px solid #eeeeee" }}>
          <div className="opacity-75">Referral Rewards</div>
          <div>
            {referralRewardsBN.isNaN()
              ? "0"
              : getDisplayBalance(referralRewardsBN)}{" "}
            USDT
          </div>
          <div className="opacity-50 text-xs">Unlocked</div>
        </div>
        <div className="flex-1">
          <div className="opacity-75">Binary System Rewards</div>
          <div>
            {binaryDBTBN.isNaN()
              ? "0"
              : getDisplayBalance(binaryDBTBN)}{" "}
            DBT
          </div>
          <div className="opacity-50 text-xs">Locked for 3 months</div>
        </div>
      </div>
      <div className="flex flex-row text-xs w-full my-2">
        <div className="flex-1" style={{ borderRight: "1px solid #eeeeee" }}>
          <div className="opacity-75">Mega Match Rewards</div>
          <div>
            {megaDBTBN.isNaN()
              ? "0"
              : getDisplayBalance(megaDBTBN)}{" "}
            DBT
          </div>
          <div className="opacity-50 text-xs">Locked for 3 months</div>
        </div>
        <div className="flex-1">
          <div className="opacity-75">Title Rewards</div>
          <div>
            {titleDBTBN.isNaN()
              ? "0"
              : getDisplayBalance(titleDBTBN)}{" "}
            DBT
          </div>
          <div className="opacity-50 text-xs">Unlocked</div>
        </div>
      </div>
    </Card>
  );
};

export default Deposit;
