import { useCallback, useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { useWallet } from "use-wallet";
import { provider } from "web3-core";
import useBlock from "../../../hooks/eth/useBlock";
import {
  getProfitWithdrawLimit,
  getTotalDeposit,
  getTotalDirectRewards,
  getTotalProfit,
  getTotalUniLevelReward,
  getUserBaseProfit,
  getUserExpProfit,
  getUserStage,
  getWithdrawApplication,
  getBinaryInfo,
  getReferralRewards,
  getBasicInfo,
  getWithdrawal,
} from "./finance";

export const useTotalDeposit = (): BigNumber => {
  const [value, setValue] = useState(new BigNumber(0));
  const { account, ethereum } = useWallet<provider>();
  const block = useBlock();
  console.log("block", block);

  const fetchUserPrinciple = useCallback(async () => {
    const value = await getTotalDeposit(ethereum, account);
    setValue(new BigNumber(value));
  }, [account, ethereum]);

  useEffect(() => {
    if (account && ethereum) {
      fetchUserPrinciple().then();
    }
  }, [account, ethereum, setValue, block]);

  return value;
};

export const useUserStage = (): string => {
  const [value, setValue] = useState("-");
  const { account, ethereum } = useWallet<provider>();
  const block = useBlock();

  const fetchValue = useCallback(async () => {
    const value = await getUserStage(ethereum, account);
    setValue(value);
  }, [account, ethereum]);

  useEffect(() => {
    if (account && ethereum) {
      fetchValue().then();
    }
  }, [account, ethereum, setValue, block]);

  return value;
};

export const useWithdrawApplication = (): {
  withdrawingAmount;
  withdrawingCountdown;
} => {
  const [withdrawingAmount, setWithdrawingAmount] = useState(new BigNumber(0));
  const [withdrawingCountdown, setwithdrawingCountdown] = useState("-");
  const { account, ethereum } = useWallet<provider>();
  const block = useBlock();

  const fetchWithdrawApplication = useCallback(async () => {
    const [withdrawingAmount, withdrawingCountdown] =
      await getWithdrawApplication(ethereum, account);
    setWithdrawingAmount(withdrawingAmount);
    setwithdrawingCountdown(withdrawingCountdown);
  }, [account, ethereum]);

  useEffect(() => {
    if (account && ethereum) {
      fetchWithdrawApplication().then();
    }
  }, [account, ethereum, setWithdrawingAmount, setWithdrawingAmount, block]);

  return {
    withdrawingAmount,
    withdrawingCountdown,
  };
};

export const useBasicInfo = (): {
  interest;
  currentInterest;
  currentWithdrawable;
} => {
  const [interest, setInterest] = useState(new BigNumber(0));
  const [currentInterest, setCurrentInterest] = useState(new BigNumber(0));
  const [currentWithdrawable, setCurrentWithdrawable] = useState(
    new BigNumber(0)
  );
  const { account, ethereum } = useWallet<provider>();
  const block = useBlock();

  const fetchBasicInfo = useCallback(async () => {
    const [interest, currentInterest, currentWithdrawable] = await getBasicInfo(
      ethereum,
      account
    );
    setInterest(interest);
    setCurrentInterest(currentInterest);
    setCurrentWithdrawable(currentWithdrawable);
  }, [account, ethereum]);

  useEffect(() => {
    if (account && ethereum) {
      fetchBasicInfo().then();
    }
  }, [
    account,
    ethereum,
    setInterest,
    setCurrentInterest,
    setCurrentWithdrawable,
    block,
  ]);

  return {
    interest,
    currentInterest,
    currentWithdrawable,
  };
};

export const useBinaryInfo = (): {
  binaryDBT;
  titleDBT;
  megaDBT;
  currentBinaryDBT;
  currentTitleDBT;
  currentMegaDBT;
} => {
  const [binaryDBT, setBinaryDBT] = useState(new BigNumber(0));
  const [titleDBT, setTitleDBT] = useState(new BigNumber(0));
  const [megaDBT, setMegaDBT] = useState(new BigNumber(0));
  const [currentBinaryDBT, setCurrentBinaryDBT] = useState(new BigNumber(0));
  const [currentTitleDBT, setCurrentTitleDBT] = useState(new BigNumber(0));
  const [currentMegaDBT, setCurrentMegaDBT] = useState(new BigNumber(0));
  const { account, ethereum } = useWallet<provider>();
  const block = useBlock();

  const fetchBinaryInfo = useCallback(async () => {
    const [
      binaryDBT,
      titleDBT,
      megaDBT,
      currentBinaryDBT,
      currentTitleDBT,
      currentMegaDBT,
    ] = await getBinaryInfo(ethereum, account);
    setBinaryDBT(binaryDBT);
    setTitleDBT(titleDBT);
    setMegaDBT(megaDBT);
    setCurrentBinaryDBT(currentBinaryDBT);
    setCurrentTitleDBT(currentTitleDBT);
    setCurrentMegaDBT(currentMegaDBT);
  }, [account, ethereum]);

  useEffect(() => {
    if (account && ethereum) {
      fetchBinaryInfo().then();
    }
  }, [
    account,
    ethereum,
    setBinaryDBT,
    setTitleDBT,
    setMegaDBT,
    setCurrentBinaryDBT,
    setCurrentTitleDBT,
    setCurrentMegaDBT,
    block,
  ]);

  return {
    binaryDBT,
    titleDBT,
    megaDBT,
    currentBinaryDBT,
    currentTitleDBT,
    currentMegaDBT,
  };
};

export const useWithdrawal = (): {
  unlock;
  locked;
} => {
  const [unlock, setUnlock] = useState(new BigNumber(0));
  const [locked, setLocked] = useState(new BigNumber(0));
  const { account, ethereum } = useWallet<provider>();
  const block = useBlock();

  const fetchWithdrawal = useCallback(async () => {
    const [unlock, locked] = await getWithdrawal(ethereum, account);
    setUnlock(unlock);
    setLocked(locked);
  }, [account, ethereum]);

  useEffect(() => {
    if (account && ethereum) {
      fetchWithdrawal().then();
    }
  }, [account, ethereum, setUnlock, setLocked, block]);

  return {
    unlock,
    locked,
  };
};

export const useReferralRewards = (): {
  referralRewards;
} => {
  const [referralRewards, setReferralRewards] = useState(new BigNumber(0));
  const { account, ethereum } = useWallet<provider>();
  const block = useBlock();

  const fetchBinaryInfo = useCallback(async () => {
    const referralRewards = await getReferralRewards(ethereum, account);
    setReferralRewards(referralRewards);
  }, [account, ethereum]);

  useEffect(() => {
    if (account && ethereum) {
      fetchBinaryInfo().then();
    }
  }, [account, ethereum, setReferralRewards, block]);

  return {
    referralRewards,
  };
};

export const useRevenues = (): {
  totalProfit;
  uniLevelRewards;
  referralRewards;
} => {
  const [value1, setValue1] = useState("-");
  const [value2, setValue2] = useState("-");
  const [value3, setValue3] = useState("-");

  const { account, ethereum } = useWallet<provider>();
  const block = useBlock();

  const fetch1 = useCallback(async () => {
    const value = await getTotalProfit(ethereum, account);
    setValue1(value);
  }, [account, ethereum]);

  const fetch2 = useCallback(async () => {
    const value = await getTotalUniLevelReward(ethereum, account);
    setValue2(value);
  }, [account, ethereum]);

  const fetch3 = useCallback(async () => {
    const value = await getTotalDirectRewards(ethereum, account);
    setValue3(value);
  }, [account, ethereum]);

  useEffect(() => {
    if (account && ethereum) {
      fetch1().then();
      fetch2().then();
      fetch3().then();
    }
  }, [account, ethereum, block]);

  return {
    totalProfit: value1,
    uniLevelRewards: value2,
    referralRewards: value3,
  };
};

export const useProfitWithdrawLimit = (): string => {
  const [value, setValue] = useState("-");
  const { account, ethereum } = useWallet<provider>();
  const block = useBlock();

  const fetchValue = useCallback(async () => {
    const value = await getProfitWithdrawLimit(ethereum, account);
    setValue(value);
  }, [account, ethereum]);

  useEffect(() => {
    if (account && ethereum) {
      fetchValue().then();
    }
  }, [account, ethereum, setValue, block]);

  return value;
};

export const useUserExpProfit = (): {
  amount: string;
  duration: string;
} => {
  const [amount, setAmount] = useState("0");
  const [duration, setDuration] = useState("0");
  const { account, ethereum } = useWallet<provider>();
  const block = useBlock();

  const fetchValue = useCallback(async () => {
    const value = await getUserExpProfit(ethereum, account);
    // console.log(value)
    // setAmount(value[0])
    // setDuration(value[1])
  }, [account, ethereum]);

  useEffect(() => {
    if (account && ethereum) {
      fetchValue().then();
    }
  }, [account, ethereum, setDuration, setAmount, block]);

  return {
    amount,
    duration,
  };
};
export const useUserBaseProfit = (): {
  amount: string;
  duration: string;
} => {
  const [amount, setAmount] = useState("0");
  const [duration, setDuration] = useState("0");
  const { account, ethereum } = useWallet<provider>();
  const block = useBlock();

  const fetchValue = useCallback(async () => {
    // const value = await getUserBaseProfit(ethereum, account)
    // setAmount(value[0])
    // setDuration(value[1])
  }, [account, ethereum]);

  useEffect(() => {
    if (account && ethereum) {
      fetchValue().then();
    }
  }, [account, ethereum, setDuration, setAmount, block]);

  return {
    amount,
    duration,
  };
};
