import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './assets/styles/tailwind.out.css'
import './assets/styles/main.css'
import Cookies from 'js-cookie'
import BigNumber from 'bignumber.js'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 1000,
})

const getQueryStringValue = (key) => {
  return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

if (getQueryStringValue('uni')) {
  Cookies.set('DEFI_MY_REFEREE', getQueryStringValue('uni'))
}

if (getQueryStringValue('binary')) {
  Cookies.set('DEFI_BINARY_MY_REFEREE', getQueryStringValue('binary'))
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
