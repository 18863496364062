import Web3 from "web3";
import { provider } from "web3-core";
import { Contract } from "web3-eth-contract";
import { AbiItem } from "web3-utils";
import { ethers } from "ethers";
import ERC20ABI from "./ERC20.json";
import {
  FinanceContractAddress as uniFinanceContractAddress,
  UsdtContractAddress as uniUsdtContractAddress,
} from "../views/Defi/hooks/constants";
import {
  FinanceContractAddress as binaryFinanceContractAddress,
  UsdtContractAddress as binaryUsdtContractAddress,
} from "../views/Defi2/hooks/constants";
import {
  FinanceContractAddress as proxyFinanceContractAddress,
  UsdtContractAddress as proxyUsdtContractAddress,
} from "../views/Defi3/hooks/constants";

export const getErc20Contract = (provider: provider, address: string) => {
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(
    ERC20ABI.abi as unknown as AbiItem,
    address
  );
  return contract;
};

export const approve = async (
  provider: provider,
  account: string,
  type: string
): Promise<string> => {
  let usdtAddress, financeAddress;
  if (type === "uni") {
    usdtAddress = uniUsdtContractAddress;
    financeAddress = uniFinanceContractAddress;
  } else if (type === "binary") {
    usdtAddress = binaryUsdtContractAddress;
    financeAddress = binaryFinanceContractAddress;
  } else {
    usdtAddress = proxyUsdtContractAddress;
    financeAddress = proxyFinanceContractAddress;
  }

  const usdt = getErc20Contract(provider, usdtAddress);
  try {
    await usdt.methods
      .approve(financeAddress, ethers.constants.MaxUint256)
      .send({ from: account });
  } catch (e: any) {
    return;
  }
};

export const getAllowance = async (
  lpContract: Contract,
  masterChefContract: Contract,
  account: string
): Promise<string> => {
  try {
    const allowance: string = await lpContract.methods
      .allowance(account, masterChefContract.options.address)
      .call();
    return allowance;
  } catch (e: any) {
    return "0";
  }
};

export const getBalance = async (
  provider: provider,
  tokenAddress: string,
  userAddress: string
): Promise<string> => {
  const lpContract = getErc20Contract(provider, tokenAddress);
  try {
    const balance: string = await lpContract.methods
      .balanceOf(userAddress)
      .call();
    return balance;
  } catch (e: any) {
    return "0";
  }
};
