import React, { useCallback, useState } from 'react'
import { useWallet } from 'use-wallet'
import { provider } from 'web3-core'
import { useReferee as useRefereeUni } from '../hooks/useInvite_uni'
import { useReferee as useRefereeBinary } from '../hooks/useInvite_binary'
import { Card, CardHeader } from './Common'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const Deposit: React.FC = () => {
  const [copied, setCopied] = useState(false)
  const { account } = useWallet<provider>()
  const refereeUni = useRefereeUni()
  const refereeBinary = useRefereeBinary()

  const isReferredUni = refereeUni && parseInt(refereeUni) !== 0
  const isReferredBinary = refereeBinary && parseInt(refereeBinary) !== 0

  const link = `${window.origin}/?uni=${account}&binary=${account}`
  const onCopy = useCallback(() => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }, [setCopied])

  return (
    <Card className="flex flex-col justify-center items-center text-center py-2">
      <CardHeader>My Referral</CardHeader>
      <div className="flex-1 flex flex-col justify-start items-center pb-2">
        {
          isReferredUni && account && isReferredBinary &&
          <CopyToClipboard text={link} onCopy={onCopy}
            className="text-xs underline cursor-pointer">
            <span>{copied ? 'Copied!' : 'Copy invitation link'}</span>
          </CopyToClipboard>
        }
      </div>
    </Card>
  )
}

export default Deposit
