import Web3 from "web3";
import { provider } from "web3-core";
import { AbiItem } from "web3-utils";
import FinanceABI from "./finance.json";
import { FinanceContractAddress } from "./constants";
import BigNumber from "bignumber.js";

export const getFinanceContract = (provider: provider) => {
  const web3 = new Web3(provider);
  return new web3.eth.Contract(
    FinanceABI as unknown as AbiItem,
    FinanceContractAddress
  );
};

export const getTotalDeposit = async (
  provider: provider,
  userAddress: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    const result = await lpContract.methods.getTotalDeposit(userAddress).call();
    return result;
  } catch (e: any) {
    return null;
  }
};

export const getUserStage = async (
  provider: provider,
  userAddress: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    const result = await lpContract.methods.getUserStage(userAddress).call();
    return result;
  } catch (e: any) {
    return null;
  }
};

export const getUserPrinciple = async (
  provider: provider,
  userAddress: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    const result = await lpContract.methods
      .getUserPrinciple(userAddress)
      .call();
    return [result["0"], result["1"]];
  } catch (e: any) {
    return [null, null];
  }
};

export const getBasicInfo = async (
  provider: provider,
  userAddress: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    const result = await lpContract.methods.getBasicInfo(userAddress).call();
    return [result["0"], result["1"], result["2"]];
  } catch (e: any) {
    return [null, null, null];
  }
};

export const getWithdrawal = async (
  provider: provider,
  userAddress: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    const result = await lpContract.methods.getWithdrawal(userAddress).call();
    return [result["0"], result["1"]];
  } catch (e: any) {
    return [null, null];
  }
};

export const getBinaryInfo = async (
  provider: provider,
  userAddress: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    const result = await lpContract.methods.getBinaryInfo(userAddress).call();
    return [
      result["0"],
      result["1"],
      result["2"],
      result["3"],
      result["4"],
      result["5"],
    ];
  } catch (e: any) {
    return [null, null, null, null, null, null];
  }
};

export const getReferralRewards = async (
  provider: provider,
  userAddress: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    const result = await lpContract.methods
      .getReferralRewards(userAddress)
      .call();

    return result;
  } catch (e: any) {
    return null;
  }
};

export const getWithdrawApplication = async (
  provider: provider,
  userAddress: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    const result = await lpContract.methods
      .getWithdrawApplication(userAddress)
      .call();
    return [result["0"], result["1"]];
  } catch (e: any) {
    return [null, null];
  }
};

export const getUserBaseProfit = async (
  provider: provider,
  userAddress: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    const result = await lpContract.methods
      .getUserBaseProfit(userAddress)
      .call();
    return result;
  } catch (e: any) {
    return null;
  }
};

export const getUserExpProfit = async (
  provider: provider,
  userAddress: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    const result = await lpContract.methods
      .getUserExpProfit(userAddress)
      .call();
    return result;
  } catch (e: any) {
    return null;
  }
};

export const getTotalDirectRewards = async (
  provider: provider,
  userAddress: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    return await lpContract.methods.getTotalDirectRewards(userAddress).call();
  } catch (e: any) {
    return null;
  }
};

export const getTotalUniLevelReward = async (
  provider: provider,
  userAddress: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    return await lpContract.methods.getTotalUniLevelReward(userAddress).call();
  } catch (e: any) {
    return null;
  }
};

export const getTotalProfit = async (
  provider: provider,
  userAddress: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    return await lpContract.methods.getTotalProfit(userAddress).call();
  } catch (e: any) {
    return null;
  }
};

export const getProfitWithdrawLimit = async (
  provider: provider,
  userAddress: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    return await lpContract.methods.getProfitWithdrawLimit(userAddress).call();
  } catch (e: any) {
    return null;
  }
};

export const onDeposit = async (
  provider: provider,
  account: string,
  amount: number,
  referee1: string,
  referee2: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    const amountBN = new BigNumber(amount)
      .times(new BigNumber(10).pow(18))
      .toString();
    console.log("onDeposit", referee1, referee2, amountBN);
    await lpContract.methods
      .swap(referee1, referee2, amountBN)
      .send({ from: account, gasLimit: 3000000 })
      .on("transactionHash", (tx) => {
        console.log(tx);
        return tx.transactionHash;
      });
  } catch (e: any) {
    return null;
  }
};

export const onWithdraw = async (
  provider: provider,
  account: string,
  withdrawable: number
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    console.log("onWithdraw", withdrawable);
    await lpContract.methods
      .applyWithdraw(withdrawable)
      .send({ from: account })
      .on("transactionHash", (tx) => {
        console.log(tx);
        return tx.transactionHash;
      });
  } catch (e: any) {
    return null;
  }
};

export const onHarvest = async (
  provider: provider,
  account: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    console.log("onHarvest");
    await lpContract.methods
      .harvestToken()
      .send({ from: account })
      .on("transactionHash", (tx) => {
        console.log(tx);
        return tx.transactionHash;
      });
  } catch (e: any) {
    return null;
  }
};

export const onCompound = async (
  provider: provider,
  account: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    console.log("onCompound");
    await lpContract.methods
      .reDeposit()
      .send({ from: account })
      .on("transactionHash", (tx) => {
        console.log(tx);
        return tx.transactionHash;
      });
  } catch (e: any) {
    return null;
  }
};

export const onApplyBinaryReward = async (
  provider: provider,
  account: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    console.log("onApplyBinaryReward");
    await lpContract.methods
      .applyBinaryReward()
      .send({ from: account })
      .on("transactionHash", (tx) => {
        console.log(tx);
        return tx.transactionHash;
      });
  } catch (e: any) {
    return null;
  }
};

export const onHarvestCurrency = async (
  provider: provider,
  account: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    console.log("onHarvestCurrency");
    await lpContract.methods
      .harvestCurrency()
      .send({ from: account })
      .on("transactionHash", (tx) => {
        console.log(tx);
        return tx.transactionHash;
      });
  } catch (e: any) {
    return null;
  }
};

export const onHelpReferral = async (
  provider: provider,
  account: string,
  amount: number,
  referee: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    console.log("onHelpReferral", amount, referee);
    await lpContract.methods
      .helpReferral(
        referee,
        new BigNumber(amount).times(new BigNumber(10).pow(18))
      )
      .send({ from: account })
      .on("transactionHash", (tx) => {
        console.log(tx);
        return tx.transactionHash;
      });
  } catch (e: any) {
    return null;
  }
};
