const formatDuration = (duration: any) => {
  const seconds = parseInt(duration)
  if (!Number.isInteger(seconds)) {
    return '-'
  }

  const minutes = Math.floor(seconds / 60) % 60
  const hours = Math.floor(seconds / 3600) % 24
  const days = Math.floor(seconds / (3600 * 24))

  if (days <= 0) {
    return `${hours} hours ${minutes} minutes`
  }
  return `${days} days ${hours} hours ${minutes} minutes`
}

export default formatDuration
