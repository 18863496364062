import { useCallback, useEffect, useState } from 'react'
import { useWallet } from 'use-wallet'
import { provider } from 'web3-core'
import useBlock from '../../../hooks/eth/useBlock'
import { getReferee, getReferrals } from './invite_uni'


export const useReferee = (): string => {
  const [value, setValue] = useState(null)
  const { account, ethereum } = useWallet<provider>()
  const block = useBlock()

  const fetch = useCallback(async () => {
    const value = await getReferee(ethereum, account)
    console.log('referee', value)
    setValue(value)
  }, [account, ethereum])

  useEffect(() => {
    if (account && ethereum) {
      fetch().then()
    }
  }, [account, ethereum, setValue, block])

  return value
}


export const useReferrals = (): string[] => {
  const [value, setValue] = useState(null)
  const { account, ethereum } = useWallet<provider>()
  const block = useBlock()

  const fetch = useCallback(async () => {
    const value = await getReferrals(ethereum, account)
    console.log('referrals', value)
    setValue(value)
  }, [account, ethereum])

  useEffect(() => {
    if (account && ethereum) {
      fetch().then()
    }
  }, [account, ethereum, setValue, block])

  return value
}
