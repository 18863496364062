import Web3 from "web3";
import { provider } from "web3-core";
import { AbiItem } from "web3-utils";
import FinanceABI from "../../Defi2/hooks/finance.json";
import { FinanceContractAddress } from "../../Defi2/hooks/constants";
import BigNumber from "bignumber.js/bignumber";

BigNumber.config({ EXPONENTIAL_AT: 256 });

export const getFinanceContract = (provider: provider) => {
  const web3 = new Web3(provider);
  return new web3.eth.Contract(
    FinanceABI as unknown as AbiItem,
    FinanceContractAddress
  );
};

export const getUsersNum = async (provider: provider): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    const result = await lpContract.methods.getUsersNum().call();
    return result;
  } catch (e: any) {
    return null;
  }
};

export const getUserTotalDepositNum = async (
  provider: provider,
  address: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    const result = await lpContract.methods
      .getTotalDepositNum(address)
      .call();
    return result;
  } catch (e: any) {
    return null;
  }
};

export const getUserDepositRecordByIndex = async (
  provider: provider,
  address: string,
  index: number
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    const result = await lpContract.methods
      .getDepositRecordByIndex(address, index)
      .call();
    return result;
  } catch (e: any) {
    return null;
  }
};

export const getUserAddressByIndex = async (
  provider: provider,
  index: number
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    const result = await lpContract.methods.getUserAddressByIndex(index).call();
    return result;
  } catch (e: any) {
    return null;
  }
};

export const onWithdrawCurrencyByOwner = async (
  provider: provider,
  account: string,
  amount: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    console.log("withdrawCurrencyByOwner");
    await lpContract.methods
      .withdrawCurrencyByOwner(
        new BigNumber(amount).times(Math.pow(10, 18)).toString()
      )
      .send({ from: account })
      .on("transactionHash", (tx) => {
        console.log(tx);
        return tx.transactionHash;
      });
  } catch (e: any) {
    return null;
  }
};

export const onWithdrawTokenByOwner = async (
  provider: provider,
  account: string,
  amount: string
): Promise<any> => {
  try {
    const lpContract = getFinanceContract(provider);
    console.log(
      "onWithdrawTokenByOwner",
      new BigNumber(amount).times(Math.pow(10, 18)).toString()
    );
    await lpContract.methods
      .withdrawTokenByOwner(
        new BigNumber(amount).times(Math.pow(10, 18)).toString()
      )
      .send({ from: account })
      .on("transactionHash", (tx) => {
        console.log(tx);
        return tx.transactionHash;
      });
  } catch (e: any) {
    return null;
  }
};
