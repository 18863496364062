import React from 'react'
import Button from '../../Button'
import Card from '../../Card'
import CardContent from '../../CardContent'
import CardIcon from '../../CardIcon'
import CardTitle from '../../CardTitle'
import Spacer from '../../Spacer'

interface WalletCardProps {
  icon: React.ReactNode
  onConnect: () => void
  title: string
}

const WalletCard: React.FC<WalletCardProps> = ({ icon, onConnect, title }) => (
  <div className="flex flex-col justify-center items-center">
    <CardIcon>{icon}</CardIcon>
    <div className="text-lg text-center">{title}</div>
    <Spacer />
    <div className="w-auto">
      <Button onClick={onConnect} text="Connect" />
    </div>
  </div>
)

export default WalletCard
