import { useCallback, useEffect, useState } from "react";

import BigNumber from "bignumber.js";
import { useWallet } from "use-wallet";
import { Contract } from "web3-eth-contract";
import { getAllowance, getErc20Contract } from "../utils/erc20";
import { provider } from "web3-core";
import { UsdtContractAddress as uniUsdtContractAddress } from "../views/Defi/hooks/constants";
import { UsdtContractAddress as binaryUsdtContractAddress } from "../views/Defi2/hooks/constants";
import { UsdtContractAddress as proxyUsdtContractAddress } from "../views/Defi3/hooks/constants";
import { getFinanceContract as uniGetFinanceContract } from "../views/Defi/hooks/finance";
import { getFinanceContract as binaryGetFinanceContract } from "../views/Defi2/hooks/finance";
import { getFinanceContract as proxyGetFinanceContract } from "../views/Defi3/hooks/finance";

const useAllowance = (provider: provider, type: String) => {
  let usdtAddress, contract;
  if (type === "uni") {
    usdtAddress = uniUsdtContractAddress;
    contract = uniGetFinanceContract(provider);
  } else if (type === "binary") {
    usdtAddress = binaryUsdtContractAddress;
    contract = binaryGetFinanceContract(provider);
  } else {
    usdtAddress = proxyUsdtContractAddress;
    contract = proxyGetFinanceContract(provider);
  }

  const tokenContract = getErc20Contract(provider, usdtAddress);

  const [allowance, setAllowance] = useState(new BigNumber(0));
  const { account } = useWallet();

  const fetchAllowance = useCallback(async () => {
    const allowance = await getAllowance(tokenContract, contract, account);
    setAllowance(new BigNumber(allowance));
  }, [account]);

  useEffect(() => {
    if (account && contract && tokenContract) {
      fetchAllowance();
    }
    let refreshInterval = setInterval(fetchAllowance, 10000);
    return () => clearInterval(refreshInterval);
  }, [account]);

  return allowance;
};

export default useAllowance;
